import React, { useEffect } from "react";
import "../styles/home.css";
import HomeBanner from "../images/thali8.svg";
import Logo from "../images/logo.png";
import Navbar from "../elements/navbar";
import DishCard from "../elements/dishcard";
import Footer from "../elements/footer";
import Thali from "../images/thali.jpg";
import Paratha from "../images/paratha.jpg";
import Thali2 from "../images/thali4.jpg";
import Thali5 from "../images/thali5.jpg";
import Sandwich from "../images/sandwich.jpg";
import Sambar from "../images/sambar.jpg";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <div className="homemain">
      <Navbar />
      <div className="homemaintop">
        <div className="homemaintopleft">
          <img src={Logo} alt="Logo" className="lefttoplogo" />
          <h1>
            <strong>Oriental Well Being Food</strong>
          </h1>

          <p>A Little Piece of Home in Every Bite</p>
          <button className="menubutton" onClick={() => navigate("/menu")}>
            Check Out Our Menu
          </button>
        </div>
        <div className="homemaintopright">
          <div className="homemaintoprightbg"></div>
          <img src={HomeBanner} alt="Homebanner" />
        </div>
      </div>
      <div className="homemaindishes">
        <div className="homemaindishesheader">
          <h2 className="sectionheaders">
            Our Top <strong>Sellers</strong>
          </h2>

          <p>
            Bursting with spices and rich flavors, every dish tells a story.
          </p>
          <div className="dishgrid">
            <DishCard
              dishimage={Thali}
              name="Pocket Friendly"
              description="2 Roti, 1 Sabzi, 1 Rice, 1 Cup Curd"
              rating="4.7"
              price="7.99"
            />
            <DishCard
              dishimage={Paratha}
              name="Aloo Paratha"
              description="2 Parathas"
              rating="4.5"
              price="9.99"
            />
            <DishCard
              dishimage={Sandwich}
              name="Cheese Sandwich"
              description="1 Sandwich"
              rating="4.9"
              price="9.99"
            />
            <DishCard
              dishimage={Sambar}
              name="Sambar Rice"
              description="1 Cup Sambar and Rice"
              rating="4.8"
              price="6.99"
            />
          </div>
        </div>
      </div>
      <div className="homemainabout">
        <div className="homemainaboutleft">
          <div className="homemainaboutleftbg"></div>
          <img src={Thali2} alt="Dish" />
        </div>
        <div className="homemainaboutright">
          <h2 className="sectionheaders">
            Welcome to <br />
            <strong>Oriental Well Being Food</strong>
          </h2>
          <p>
            Welcome to Oriental Well Being Food, where we bring together the
            rich traditions of Indian cuisine with the spiritual essence of
            Vaishnav-style cooking. Our mission is to deliver freshly prepared,
            wholesome vegetarian meals that nourish your body, mind, and soul,
            while staying true to the principles of purity and devotion.
          </p>
          <div className="centeralign">
            <button className="menubutton" onClick={() => navigate("/menu")}>
              Check Our Menu
            </button>
          </div>
        </div>
      </div>
      <div className="homesubscription">
        <div className="homesubscriptionleft">
          <h2 className="sectionheaders">
            Subscribe to Our
            <br />
            <strong>Home Cooked Tiffin</strong>
          </h2>
          <p>
            Enjoy the convenience of our monthly tiffin service! Savor fresh,
            delicious, and nutritious meals crafted just for you. Subscribe now
            for hassle-free dining and experience the taste of home every day!
          </p>
          <button className="contactbutton centeralign">
            <ion-icon name="call"></ion-icon>
            +353 872228603
          </button>
        </div>
        <div className="homesubscriptionright">
          <DishCard
            dishimage={Thali5}
            name={"Monthly Tiffin Plan"}
            price={8.99}
            description={
              "Enjoy fresh meals every day with our menu featuring 3 rotis, 1 curry, dal, and rice."
            }
            rating={4.7}
          />
        </div>
      </div>
      <div className="homebottom">
        <h1>Why Order From Us?</h1>
        <p>
          At Oriental Well Being Food, we are dedicated to creating meals that
          honor the sacred practices of Vaishnav cooking, rooted in the ancient
          teachings of Lord Krishna. Our dishes are prepared with the utmost
          care and respect, using only the finest ingredients. Our meals are
          infused with the devotion and love that define Vaishnav cuisine,
          aiming to create a spiritual dining experience that goes beyond taste.
        </p>
        <p>
          Whether you're a busy professional, a student, or someone who
          appreciates the profound benefits of a sattvic and Vaishnav diet,
          Oriental Well Being Food is here to provide you with meals that are
          not only delicious but also spiritually uplifting. We cater to a
          variety of dietary preferences and needs, ensuring everyone can enjoy
          the subtle and vibrant flavors of Indian vegetarian cuisine, prepared
          in the Vaishnav tradition.
        </p>
        <p>
          Let Oriental Well Being FoodHome cooked Indian Vegetarian Tiffin
          Services be your guide on a culinary journey that celebrates the
          essence of Indian vegetarian cooking and the sacredness of Vaishnav
          traditions.
        </p>
        <strong>
          With "A Little Piece of Home in Every Bite," experience the joy,
          peace, and devotion of a home-cooked, sattvic meal in every dish.
        </strong>
        <button className="contactbutton">
          <ion-icon name="call"></ion-icon>
          +353 872228603
        </button>
      </div>
      <Footer />
    </div>
  );
}
